"use client"
import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Social from './social'
import { getIcon } from '@/lib/get-icon'
import commonIcons from '../../icons/common-icons'
import footerIcons from '../../icons/footer-icons'
import Container from '@/ui/container'
import AutoBookDemo from '@/components/common/auto-book-demo'
import useYscroll from '@/components/common/functions'
import { usePathname } from 'next/navigation'
// import Cookies from '@/components/common/Cookies'
// import { string } from 'yup'
// const Talk = dynamic(() => import('./talk'))



function Footer() {

  const [containerLoading, setContainerLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setContainerLoading(true)
    }, 300)
  }, [])

  let footer = [
    {
      title: 'SOLUTIONS',
      items: [
        { title: "Hypermarkets", link: "/solutions/hypermarkets" },
        { title: "Healthcare & Wellness", link: "/solutions/healthcare-and-wellness" },
        { title: "Home Furnishing & Decor", link: "/solutions/home-furnishing" },
        { title: "Consumer Durables & IT", link: "/solutions/consumer-durables" },
        { title: "Apparel & Lifestyle", link: "/solutions/apparels" },
        { title: "AI for Retail", link: "/solutions/artificial-intelligence" },
      ]
    },
    {
      title: 'Segment',
      items: [
        // { title: "Hypermarket", link: "/solutions/hypermarkets" },
        // { title: "Consumer Goods", link: "/solutions/consumer-durables" },
        //  { title: "Health and Pharmacy", link: "/solutions/healthcare-and-wellness" },
        { title: "Mid Enterprise", link: "/solutions/mid-market-enterprise" },
        { title: "Large Enterprise", link: "/solutions/large-enterprise" },
      ]
    },
    {
      title: 'PRODUCTS',
      items: [
        { title: "Izoleap", link: "/products/izoleap" },
        { title: "Izowhiz", link: "/products/izowhiz" },
        { title: "Izoware", link: "/products/izoware" },
      ]
    },
    {
      title: 'WHY INVENZO',
      items: [
        { title: "About Us", link: "/why-invenzo/about-us" },
        { title: "Our Story", link: "/why-invenzo/our-story" },
        { title: "Careers", link: "/why-invenzo/careers" },
      ]
    },
    {
      title: 'COMPANY',
      items: [
        { title: "Contact Us", link: "/contact-us" },
        { title: "Our Team", link: "/our-team" },
        { title: "Customers", link: "/our-customers" },
        { title: "Blog", link: "/blog" },
        { title: "Sitemap", link: "/sitemap" },
      ]
    },
  ]

  const [cookie, setCookie] = useState(false);

  useEffect(() => {
    localStorage.cookie_accept ? setCookie(false) : setCookie(true);
  }, [])


  let [isRequestOpen, setIsRequestOpen] = useState(true)
  function closeRequestModal() {
    setIsRequestOpen(false);
  }
  function openRequestModal() {
    setIsRequestOpen(true);
  }
  const { shown } = useYscroll({})

  const isBrowser = () => typeof window !== 'undefined'; //The approach recommended by Next.js

  function scrollToTop() {
    if (!isBrowser()) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Show the button when the user scrolls down
    if (window.scrollY > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const pathname = usePathname()
  const pageRoot = pathname?.split('/')



  return (
    <>{shown &&
      <div className={`${pageRoot[1] === 'pwa' ? "hidden" : ""} max-w-full px-0 relative bg-[#F9F4EF]`}>
        <Image property='true' quality={60} width={1500} height={41} src={"/images/footer-top-bg.png"} alt={'Footer top'} className='absolute lg:-top-[44px] xl:-top-[41px] w-full h-[50px] left-0 bg-[length:100%] bg-no-repeat' />
        <Container className='max-w-full px-10 xs:px-5 relative pt-14 xs:pt-5'>
          {/* <Talk /> */}

          <div className='relative font-secondary text-sm text-[#5c5252] font-medium'>

            <div className='flex xs:flex-col sm:flex-wrap sm:w-full justify-between relative sm:justify-start sm:gap-8'>

              {/* <Link href="/" > */}
              {getIcon({ iconList: footerIcons, iconName: 'FooterLogo', className: 'xs:w-40 sm:w-full sm:mb-10' })}
              {/* </Link> */}

              {footer.map((main, i) =>
                <div key={i + " footer_links"} className='flex-nowrap mb-5 sm:first-of-type:w-[65%] sm:w-[30%]'>
                  <div className='text-base xs:text-sm font-extrabold text-[#000000] mb-2 uppercase'>{main.title}</div>
                  <ul>
                    {
                      main.items.map((v, index) =>
                        <li key={index + "footer_link_navigation"} className='flex items-center gap-3 mb-0'>
                          {getIcon({ iconList: footerIcons, iconName: 'MenuIcon', className: 'xs:w-3 xs:h-3' })}
                          <Link href={v?.link} className='text-lg xs:text-sm sm:text-base font-medium text-[#000000] px-4 py-2'>{v?.title}</Link>
                        </li>
                      )
                    }
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Container>

        <Container className='max-w-full px-10 xs:px-5 mt-16'>
          <Social />
        </Container>

        <div className='lg:flex justify-between lg:flex-wrap mt-3 py-5 px-10 xs:px-5 text-[15px] border-t border-[#ced4da]'>
          <span>© Invenzolabs 2015-2024 | All Rights Reserved ! Various trademarks held by their respective owners.</span>
          <div className='flex xs:flex-wrap xs:justify-start my:5 gap-2'>
            <Link href="/cms/privacy-policy" className='py-1 px-2 xs:px-0 underline-offset-1 whitespace-nowrap'>Privacy Policy</Link>
            <Link href="/cms/cookies-policy" className='py-1 px-2 xs:px-0 underline-offset-1 whitespace-nowrap'>Cookies Policy</Link>
            <Link href="/cms/terms" className='py-1 px-2 xs:px-0 underline-offset-1 whitespace-nowrap'>Terms & Conditions</Link>
            {/* <Link href="/cms/security" className='py-1 px-2 xs:px-0 underline-offset-1 whitespace-nowrap'>Security</Link> */}
          </div>
        </div>

        {/* {cookie && <Cookies accept={() => (localStorage.setItem('cookie_accept', "true" ), setCookie(false))} close={() => setCookie(false)} />} */}
        {/* {pageRoot[1] !== 'pwa' &&
                
                containerLoading && <AutoBookDemo isRequestOpen={isRequestOpen} closeRequestModal={closeRequestModal} pageRoot={pageRoot} />
             
              } */}

        {pageRoot[1] !== 'pwa' &&
          containerLoading &&
          pageRoot[1] !== 'contact-us' &&
          pageRoot[1] !== 'thank-you' &&
          pageRoot[1] !== 'demo-thank-you' &&
          pageRoot[1] !== 'izoleap-landing' && (
            <AutoBookDemo
              isRequestOpen={isRequestOpen}
              closeRequestModal={closeRequestModal}
              pageRoot={pageRoot}
            />
          )
        }

        <div onClick={scrollToTop} className={`${isVisible ? "" : "opacity-0"} transition-opacity duration-300 fixed bottom-2 right-0 z-10 cursor-pointer`}>{getIcon({ iconList: commonIcons, iconName: "goTopIcon", className: "xs:w-16 xs:h-16" })}</div>
      </div>
    }</>
  )
}

export default Footer