// import Container from "@/components/ui/container";
"use client"
import Link from "next/link";
import React, { Fragment, useRef, useState } from "react";
import { Menu, Transition } from '@headlessui/react';
import Image from "next/image";
import commonIcons from "@/components/icons/common-icons";
import { getIcon } from "@/lib/get-icon";
import { usePathname } from "next/navigation";

// import dynamic from "next/dynamic";
// import MobileHeader from "../mobile-header/MobileHeader";
interface Props {
  variant?: "default" | "modern";
}

type DivElementRef = React.MutableRefObject<HTMLDivElement>;
const Header: React.FC<Props> = ({ variant = "default" }) => {
  const pathname = usePathname()
  const pageRoot = pathname?.split('/')

  const [stickyNav, setStickyNav] = useState("shadow-none");
  const [mobileHeader, setMobileHeader] = useState(false);
  const menuRef = useRef(null);

  if (typeof window !== "undefined") {
    window.onscroll = () => {
      let addShadow;
      let top = window.scrollY;
      if (top > 50) {
        addShadow = "shadow-md";
      }
      else {
        addShadow = "shadow-none";
      }
      return setStickyNav(addShadow)
    }

  }
  const siteHeaderRef = useRef() as DivElementRef;

  const nav_solutions_one = [
    {
      title: 'By Industry',
      links: [
        { id: 1, title: 'Hypermarkets', link: '/solutions/hypermarkets', sub_title: 'Enabling quick commerce models', icon: 'MarketIcon' },
        { id: 2, title: 'Healthcare & Wellness', link: '/solutions/healthcare-and-wellness', sub_title: 'Curated omni-commerce for healthcare', icon: 'HealthIcon' },
        { id: 3, title: 'Home Furnishing & Decor', link: '/solutions/home-furnishing', sub_title: 'Enhance instore and online customer experience', icon: 'DecorIcon' },
        { id: 4, title: 'Consumer Durables & IT', link: '/solutions/consumer-durables', sub_title: 'Transform your brand holistically', icon: 'DurablesIcon' },
        { id: 5, title: 'Apparel & Lifestyle', link: '/solutions/apparels', sub_title: 'Personalized your Customer journeys', icon: 'ApparelsIcon' },
        { id: 6, title: 'AI for Retail', link: '/solutions/artificial-intelligence', sub_title: 'Predictive ML and Gen AI', icon: 'AIIcon' },
      ]
    }
  ]

  const nav_solutions_two = [
    {
      title: 'By Market Segment',
      links: [
        { title: 'Mid Market Enterprise', link: '/solutions/mid-market-enterprise', sub_title: 'Maximize Sales with Intelligent Hypermarket', icon: 'MidMarketIcon' },
        { title: 'Large Enterprise', link: '/solutions/large-enterprise', sub_title: 'End-to-end personalized omni-channel customer experience ', icon: 'LargeMarketIcon' },
      ]
    }
  ]

  let products = [
    { icon: "/izoleap.svg", title: "Izoleap", sub_title: 'One stop Omnichannel Retail Tech Platform', link: "/products/izoleap" },
    { icon: "/izowhiz.svg", title: "Izowhiz", sub_title: 'One stop last mile & courier fulfilment Platform', link: "/products/izowhiz" },
    { icon: "/izoware.svg", title: "Izoware", sub_title: 'One stop Warehouse Management Platform', link: "/products/izoware" },
  ]

  let why = [
    { icon: "/header/menu-icons/about.svg", title: "About Us", link: "/why-invenzo/about-us" },
    { icon: "/header/menu-icons/story.svg", title: "Our Story", link: "/why-invenzo/our-story" },
    { icon: "/header/menu-icons/careers.svg", title: "Careers", link: "/why-invenzo/careers" },
  ]

  return (
    <div className={`${pageRoot[1] === 'pwa' ? "hidden" : ""} sticky w-full top-0 z-50`}>
      <header id="siteHeader" ref={siteHeaderRef} className={`${stickyNav} bg-white `}>

        <div className='flex justify-between items-center xs:items-start lg:hidden w-full bg-white/80 pb-1'>
          <div className="p-4 xs:p-0 md:p-0">
            <Link href="/" aria-label="Know more about Invenzo Labs" tabIndex={0}>
              <Image loading="eager" src='/logo.webp' width={128} height={34} alt="invnezo Labs logo" className="xs:w-[190px] md:w-[190px]" />
            </Link>
          </div>

          <div onClick={() => setMobileHeader(!mobileHeader)} className='flex flex-col w-12 h-12 justify-center items-center'>
            <span className={`${mobileHeader ? 'rotate-45 w-5' : 'w-8'} block h-0.5 bg-gray-600 transition-transform`}></span>
            <span className={`${mobileHeader ? 'hidden' : ''} block h-0.5 w-6 bg-gray-600 my-1`}></span>
            <span className={`${mobileHeader ? '-rotate-45 w-4 relative -top-[2px]' : ''} block h-0.5 w-5 bg-gray-600 transition-transform`}></span>
          </div>
        </div>

        <div className={`${mobileHeader ? "overflow-auto h-screen lg:h-auto lg:overflow-visible xs:pt-0" : 'hidden lg:block lg:h-auto'} bg-white mx-auto transition-all`}>
          <ul className="lg:flex justify-between items-center text-base font-semibold px-10 xl:px-[130px] max-w-[1920px] mx-auto xs:px-0 sm:px-0 xs:mt-5 sm:flex-col">
            <li className="">
              <ul className="flex flex-1 justify-start gap-10 sm:gap-0 lg:gap-5 xs:gap-0 flex-col lg:flex-row w-full">
                <li className="py-3 px-4 xs:py-2">
                  <Menu as="div" className="relative inline-block text-left xs:w-full sm:w-full">
                    <div className="">
                      <Menu.Button className="flex items-center xs:w-full sm:w-full xs:justify-between sm:justify-between">
                        <span>Solutions</span> {getIcon({ iconList: commonIcons, iconName: 'DownIcon', height: '15px', className: 'ml-1' })}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="lg:absolute z-10 w-screen md:max-w-[1070px] mt-[28px] sm:mt-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white lg:shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -left-3.5">
                        <div className="md:px-0 py-0 ">
                          <Menu.Item>
                            {({ active, close }) => (
                              <>
                                <div className="grid xs:flex xs:flex-col grid-rows-1 p-8 xs:p-0 sm:p-2">
                                  {
                                    nav_solutions_one && nav_solutions_one?.map((nav: any, indexx: any) =>
                                      <ul key={nav?.id + 'solution_page_links'} className={`${indexx == 0 ? 'mb-2' : ''} grid xs:flex xs:flex-col sm:flex sm:flex-wrap grid-rows-3 grid-cols-[20%_auto] grid-flow-col gap-3 [&>*:first-child]:row-span-3`}>
                                        <li className="sm:w-full mb-2 xs:mb-0 px-2 xs:px-0 py-3 xs:py-0 text-sm font-semibold">{nav?.title}</li>
                                        {
                                          nav.links.map((res: any, index: any) =>
                                            <li onClick={() => setMobileHeader(!mobileHeader)} className='flex sm:w-[48%] mx-0 my-2' key={index + "navigation_pages_sublink"}>
                                              {getIcon({ iconList: commonIcons, iconName: `${res.icon}` })}
                                              <Link onClick={close} href={res.link} className="flex flex-col text-lg font-bold text-[#3A3A3A] mx-2 px-2">
                                                {res.title}
                                                <p className="text-sm font-normal">{res.sub_title}</p>
                                              </Link>
                                            </li>
                                          )
                                        }
                                      </ul>
                                    )
                                  }
                                </div>

                                <div className="grid grid-rows-1 bg-[#571E6610] p-8 xs:p-0 sm:p-3 xs:mt-3">
                                  {
                                    nav_solutions_two && nav_solutions_two?.map((nav, index) =>
                                      <ul key={index + 'right_solution_page_link'} className={`${index == 0 ? 'mb-2' : ''} grid xs:flex xs:flex-col sm:flex sm:flex-wrap grid-cols-[20%_auto] grid-flow-col gap-3 [&>*:first-child]:row-span-1 `}>
                                        <li className="sm:w-full mb-2 xs:mb-0 px-2 py-3 xs:py-0 text-sm font-semibold">{nav.title} </li>
                                        {
                                          nav.links.map((res, index) =>
                                            <li onClick={() => setMobileHeader(!mobileHeader)} className='flex mx-0 my-2 sm:w-[48%]' key={index + "right_navication_page_link"}>
                                              {getIcon({ iconList: commonIcons, iconName: `${res.icon}` })}
                                              <Link onClick={close} href={res.link} className="flex flex-col text-lg font-bold text-[#3A3A3A] mx-2 px-2">
                                                {res.title}
                                                <p className="text-sm font-normal">{res.sub_title}</p>
                                              </Link>
                                            </li>
                                          )
                                        }
                                      </ul>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
                <li className="py-3 px-4 xs:py-2">
                  <Menu as="div" className="relative inline-block text-left xs:w-full sm:w-full">
                    <div>
                      <Menu.Button className="flex items-center xs:w-full sm:w-full xs:justify-between sm:justify-between">
                        <span>Products</span> {getIcon({ iconList: commonIcons, iconName: 'DownIcon', height: '15px', className: 'ml-1' })}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="lg:absolute z-10 mt-2 w-[425px] xs:w-full sm:w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white lg:shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-0">
                        <div onClick={() => setMobileHeader(!mobileHeader)} className="flex flex-col md:px-1 py-1 ">
                          {
                            products.map((res, index) =>
                              <Menu.Item key={index + "products_page_link"}>
                                {({ active, close }) => (
                                  <li className='flex items-center md:py-2 border-b last:border-0 px-8 xs:px-0 sm:px-2 border-dotted'>
                                    <Image src={res?.icon} width="50" height="50" alt="icon" className="mr-3" />
                                    <Link onClick={close} href={res.link} className="ml-1 flex flex-col py-2">
                                      <span className="text-lg text-[#3A3A3A] font-bold">{res.title}</span>
                                      <span className="text-sm text-[#1D1D1D]">{res.sub_title}</span>
                                    </Link>
                                  </li>
                                )}
                              </Menu.Item>
                            )
                          }
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
                <li className="py-3 px-4 xs:py-2">
                  <Menu as="div" className="relative inline-block text-left xs:w-full sm:w-full">
                    <div>
                      <Menu.Button className="flex items-center xs:w-full sm:w-full xs:justify-between sm:justify-between">
                        Why Invenzo {getIcon({ iconList: commonIcons, iconName: 'DownIcon', height: '15px', className: 'ml-1' })}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="lg:absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white lg:shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div onClick={() => setMobileHeader(!mobileHeader)} className="flex flex-col gap-0 md:px-1">
                          {
                            why?.map((res, i) =>
                              <Menu.Item key={i + "why_invenzo_page_link"}>
                                {({ close }) => (
                                  <li className='flex items-center px-6 xs:px-2 py-5 sm:px-2 border-b border-dotted last:border-b-0'>
                                    <Image src={`https://izo-prod-websites-assets.s3.amazonaws.com/assets${res.icon}`} width="26" height="26" alt="icon" />
                                    <Link onClick={close} href={res.link} className="ml-3 text-base">
                                      {res.title}
                                    </Link>
                                  </li>
                                )}
                              </Menu.Item>
                            )
                          }
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
                <li onClick={() => setMobileHeader(!mobileHeader)} className="py-3 px-4 xs:py-2">
                  <Link className="text-[#000]" href={'/our-customers'}>Our Customers</Link>
                </li>
              </ul>
            </li>
            <li onClick={() => setMobileHeader(!mobileHeader)} className="flex-none w-auto hidden lg:flex lg:justify-center">
              <Link href={'/'}>
                {getIcon({ iconList: commonIcons, iconName: 'HeaderLogo', className: "m-auto" })}
              </Link>
            </li>
            <li className="sm:mt-3">
              <ul className="flex justify-end gap-10 xs:gap-3 sm:gap-5 lg:items-center flex-col lg:flex-row w-full xs:px-4 sm:px-4">
                <li onClick={() => setMobileHeader(!mobileHeader)}>
                  <Link className="text-[#000]" href={'/our-team'}>Our Team</Link>
                </li>
                {/* <li onClick={() => setMobileHeader(!mobileHeader)}>
                  <Link href={'/izoleap-landing'}>izo landing</Link>
                </li> */}
                <li onClick={() => setMobileHeader(!mobileHeader)}>
                  <Link className="text-[#000]" prefetch={false} href={'/contact-us'}>Contact Us</Link>
                </li>
                <li onClick={() => setMobileHeader(!mobileHeader)}>
                  <Link className="text-[#000]" href={'/blog'}>Blog</Link>
                </li>
                <li onClick={() => setMobileHeader(!mobileHeader)}>
                  <Link target="_blank" href={"https://wa.me/918310181339"}> <span className="flex items-center justify-center rounded-[4px] gap-2 border border-[#6D1178] text-[#6D1178] px-7 py-3 xs:mt-5">{getIcon({ iconList: commonIcons, iconName: 'HeadPhone', height: '20px', className: 'mr-1' })} Request Demo</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>

      </header>

      {/* {pathname !== "/" && <Breadcrumb />} */}



    </div>
  );
};

export default Header;






